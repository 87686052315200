* {
    box-sizing: border-box;
}

body {
    margin: 0;
}

:root {
    --primaryColor: #f09d51;
    --mainWhite: #fff;
    --mainBlack: #222;
    --mainGrey: #ececec;
    --mainSpacing: 0.1rem;
    --mainTransition: all 0.3s linear;
}

/* Beginning of Navbar */

a {
    text-decoration: none;
    color: var(--mainGrey);
}

ul {
    list-style: none;
    padding-inline-start: 0;
}

.nav {
    display: flex;
    align-items: center;
    min-height: 70px;
    padding: 0px 145px;
    justify-content: space-between;
    background: linear-gradient(90deg, rgba(240, 157, 81, 1) 0%, rgba(0, 0, 0, 1) 85%);
    height: 85px;

}

@media (max-width: 940px) {
    .nav {
        padding: 0px 80px;
    }
}

@media (max-width: 730px) {
    .nav {
        padding: 0px 40px;
    }
}

@media (max-width: 580px) {
    .nav {
        padding: 0px 20px;
    }
}

@media (max-width: 440px) {
    .nav {
        padding: 0px 10px;
    }
}

.nav-list {
    display: flex;
    color: rgba(0, 0, 0, 0.75);
    text-transform: uppercase;
    font-weight: 650;
    letter-spacing: var(--mainSpacing);
    background-color: transparent;
    border: none;
}

.nav-link {
    margin-right: 60px;
    color: #fff;
    position: relative;
    text-decoration: none;
}

@media (max-width: 810px) {
    .nav-link {
        font-size: 14px;
        margin-right: 40px;
    }
}

@media (max-width: 660px) {
    .nav-link {
        font-size: 12px;
        margin-right: 20px;
    }
}

@media (max-width: 440px) {
    .nav-link {
        font-size: 10px;
        margin-right: 10px;
    }
}

.nav-link::after {
    content: '';
    width: 0px;
    height: 5px;
    display: block;
    background-color: var(--primaryColor);
    transition: 300ms;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.nav-link:hover::after {
    opacity: 0.95;
    width: 100%;
    transform: translate3d(0, 0.8em, 0);
}

.logo-img {
    color: var(--mainBlack);
    text-decoration: none;
    display: block;
    height: auto;
    width: 100px;
}

@media (max-width: 660px) {
    .logo-img {
        width: 80px;
    }
}

@media (max-width: 440px) {
    .logo-img {
        width: 60px;
    }
}

/* End of Navbar */

/* Beginning of Carousel */

.carousel {
    overflow: hidden;
}

.carousel::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: radial-gradient(circle, rgba(2, 2, 2, 0) 85%, rgba(129, 65, 6, 0.404) 98%);
}

.inner {
    display: flex;
    transition: all 1s ease-out;
}


.carousel-item {
    flex: 1;
    min-width: 100%;
    box-sizing: border-box;
}

.church {
    object-fit: cover;
    width: 100%;
    height: 770px;
    opacity: 0.95;
    vertical-align: bottom;
}

.carousel-container {
    position: relative;
}

/* End of Carousel */

/* Beginning of Link Container */



.display-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

@media (max-width: 840px) {
    .display-container {
        display: grid;
    }
}

.link-btn {
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #f68f30 0, #f09d51 100%);
    box-shadow: rgba(0, 0, 0, 0.4) 0 8px 8px, rgba(0, 0, 0, 0.3) 0 7px 13px -3px, rgba(0, 0, 0, 0.5) 0 0px 0 inset;
    cursor: pointer;
    display: inline-block;
    color: var(--mainGrey);
    text-decoration: none;
    border: none;
    border-radius: 15px;
    padding: 22px;
    min-height: 25px;
    min-width: 120px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-weight: 600;
    margin: 70px;
}

@media (max-width: 1340px) {
    .link-btn {
        padding: 20px;
        font-size: 14px;
    }
}

@media (max-width: 1010px) {
    .link-btn {
        margin: 50px;
    }
}

@media (max-width: 840px) {
    .link-btn {
        padding: 18px;
        margin: 0 auto;
        margin-bottom: 50px;
    }
}

@media (max-width: 700px) {
    .link-btn {
        font-size: 12px;
    }
}

@media (max-width: 600px) {
    .link-btn {
        padding: 16px;
        font-size: 12px;
    }
}

@media (max-width: 540px) {
    .link-btn {
        padding: 18px;
        font-size: 12px;
    }
}

@media (max-width: 460px) {
    .link-btn {
        padding: 18px;
        font-size: 12px;
    }
}



.link-btn:hover {
    background-image: radial-gradient(100% 100% at 100% 0, #f68f30 0, #c05d00 100%);
}

.table-talk {
    color: var(--mainGrey);
    text-transform: uppercase;
    margin-top: 10px;
    font-size: 120px;
    text-shadow:
        -1px -1px 0 #341d06,
        1px -1px 0 #341d06,
        -1px 1px 0 #341d06,
        7px 1px 0 #341d06;
    white-space: nowrap;
    overflow: hidden;
    font-family: 'Amatic SC', sans-serif;
    letter-spacing: 0.7rem;
}

@media (max-width: 1340px) {
    .table-talk {
        font-size: 80px;
    }
}

/* Breakpoint at 1010px */
@media (max-width: 1010px) {
    .table-talk {
        font-size: 60px;
    }
}

/* Breakpoint at 840px */
@media (max-width: 840px) {
    .table-talk {
        font-size: 60px;
        text-shadow:
            -1px -1px 0 #341d06,
            1px -1px 0 #341d06,
            -1px 1px 0 #341d06,
            4px 1px 0 #341d06;
        margin-bottom: 80px;
        white-space: normal;
        line-height: 5rem;
    }
}

@media (max-width: 700px) {
    .table-talk {
        font-size: 50px;
        letter-spacing: 0.5rem;
        text-shadow:
            -1px -1px 0 #341d06,
            1px -1px 0 #341d06,
            -1px 1px 0 #341d06,
            4px 1px 0 #341d06;
        line-height: 4.5rem;
    }
}

@media (max-width: 600px) {
    .table-talk {
        font-size: 50px;
        text-shadow:
            -1px -1px 0 #341d06,
            1px -1px 0 #341d06,
            -1px 1px 0 #341d06,
            4px 1px 0 #341d06;
    }
}

@media (max-width: 540px) {
    .table-talk {
        font-size: 40px;
        text-shadow:
            -1px -1px 0 #341d06,
            1px -1px 0 #341d06,
            -1px 1px 0 #341d06,
            4px 1px 0 #341d06;
        line-height: 3.7rem;
    }
}

/* End of Link Container */

/* Beginning of Business Details */

.business-container {
    text-align: center;
    border-top: 25px solid;
    border-bottom: 25px solid;
    border-image: linear-gradient(to right, #000, #555, #000);
    border-image-slice: 1;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    /* Optional: Add a shadow effect */
}

.business {
    width: auto;
    height: 250px;
    display: inline-block;
    margin: 0px 50px;
}

@media (max-width: 700px) {
    .business {
        height: 175px;
    }
}

@media (max-width: 570px) {
    .business {
        height: 105px;
    }
}

.family-img {
    vertical-align: bottom;
}

@media (max-width: 700px) {
    .family-img {
        height: 400px;
    }
}


/* Beginning of Extended Info */

.family-container {
    display: flex;
    max-width: 100%;
}

.family-section {
    flex: 0 0 50%;
    overflow: hidden;
}

.family-img {
    object-fit: cover;
    width: 70%;
    height: 700px;
    opacity: 0.95;
    margin-left: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    object-position: center;

    /* Fancy border styles */
    border: 5px solid transparent;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

@media (max-width: 600px) {
    .family-container {
        flex-direction: column;
    }

    .family-section {
        flex: 1;
        /* Take up 100% width */
        margin: 0;
        /* Remove margins */
    }

    .family-img {
        width: 100%;
        /* Make the image take up 100% width */
        margin: 0;
        /* Remove margins */
    }
}

@media (max-width: 700px) {
    .family-img {
        height: 500px;
    }
}


.who-we-are {
    flex: 1;
    padding: 20px;
    list-style-type: none;
}

.who-we-are li {
    width: 400px;
    height: 95px;
    display: flex;
    align-items: center;
    margin: 1.5em;
    padding: 1em;
    background: rgb(43, 43, 43);
    position: relative;
    color: white;
    border-radius: 5px;
    margin-top: 100px;
    font-size: 24px;
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-weight: 600;
}

@media (max-width: 1200px) {
    .who-we-are li {
        font-size: 16px;
        width: 325px;
        height: 75px;
    }
}

@media (max-width: 900px) {
    .who-we-are li {
        font-size: 14px;
        width: 250px;
        height: 65px;
    }
}

@media (max-width: 700px) {
    .who-we-are li {
        font-size: 12px;
        width: 200px;
        height: 55px;
    }
}

@media (max-width: 700px) {
    .who-we-are li {
        font-size: 12px;
        width: 200px;
        height: 55px;
    }
}

@media (max-width: 600px) {
    .who-we-are li {
        display: none;
    }
}


.who-we-are li::before,
.who-we-are li::after {
    content: "";
    position: absolute;
    z-index: -1;
    border-radius: 5px;
    width: 105%;
    transition: all 0.4s;
}

.who-we-are li::before {
    left: 0%;
    height: 130%;
    background: linear-gradient(to right, #000000, #f09d51);
}

.who-we-are li::after {
    left: -10%;
    height: 120%;
    background: #ffffff56;
    backdrop-filter: blur(10px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.164);
}

/* Beginning of Youtube Section */

.video-section {
    max-width: 100%;
    border: 0px;
}

.mean-streak-column {
    font-size: 55px;
    color: #222;
    margin-bottom: 80px;
    text-align: center;
    margin-top: 0px;
    padding-top: 40px;
}

@media (max-width: 1500px) {
    .mean-streak-column {
        margin-bottom: 20px;
    }
}

@media (max-width: 600px) {
    .mean-streak-column {
        font-size: 35px;
    }
}


.tractor-section {
    margin-left: 50px;
}

.video-container {
    display: flex;
    margin-right: 50px;
    margin-bottom: 70px;
}

@media (max-width: 1700px) {
    .video-container {
        margin-right: 0px;
        display: grid;
        justify-content: center;
        text-align: center;
    }
}

.youtube-list {
    width: 600px;
    height: 125px;
    margin-left: 150px;
    color: var(--mainBlack);
    margin-top: 100px;
    font-size: 22px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-weight: 650;
    line-height: 55px;
}

@media (max-width: 1700px) {
    .youtube-list {
        width: 700px;
        margin-top: 80px;
        line-height: 45px;
        margin-bottom: 150px;
        margin-right: 60px;
    }
}

@media (max-width: 1500px) {
    .youtube-list {
        margin-right: 0px;
        margin-top: 0px;
    }
}

@media (max-width: 1200px) {
    .youtube-list {
        width: 600px;
        font-size: 18px;
        margin-left: 75px;
        margin-right: 0px;
        margin-top: 0px;
    }
}

@media (max-width: 900px) {
    .youtube-list {
        width: 500px;
        font-size: 14px;
        margin-left: 0px;
        line-height: 35px;
        height: 70px;
    }
}

@media (max-width: 600px) {
    .youtube-list {
        width: 400px;
        font-size: 12px;
        line-height: 30px;
        height: 30px;
    }
}

@media (max-width: 475px) {
    .youtube-list {
        width: 300px;
        font-size: 11px;
        line-height: 25px;
        height: 30px;
    }
}


.video-preview {
    border: 5px solid transparent;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    width: 1024px;
    height: 580px;
    margin-left: auto;
}

@media (max-width: 1200px) {
    .video-preview {
        width: 768px;
        height: 435px;
    }
}

@media (max-width: 900px) {
    .video-preview {
        width: 512px;
        height: 290px;
    }
}

@media (max-width: 600px) {
    .video-preview {
        width: 384px;
        height: 217.5px;
    }
}

@media (max-width: 475px) {
    .video-preview {
        width: 288px;
        height: 163.125px;
    }
}


/* End of Youtube Section */

/* Beginning of Footer Section */

.footer-container {
    background-color: var(--mainBlack);
    overflow: hidden;
    height: 275px;
    width: auto;
    position: relative;
}

.footer-head h1 {
    color: var(--mainGrey);
    text-align: center;
    margin-top: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.footer-column {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 30px;
    font-size: 18px;
}

.col-sm {
    color: var(--mainGrey);
    text-align: right;
    margin-right: 30px;
    margin-top: 25px;
}

.back-btn {
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #f68f30 0, #f09d51 100%);
    box-shadow: rgba(0, 0, 0, 0.4) 0 8px 8px, rgba(0, 0, 0, 0.3) 0 7px 13px -3px, rgba(0, 0, 0, 0.5) 0 0px 0 inset;
    cursor: pointer;
    color: var(--mainGrey);
    text-decoration: none;
    border: none;
    border-radius: 15px;
    padding: 15px;
    min-height: 25px;
    min-width: 120px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-weight: 600;
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-220%);
}

@media (max-width: 460px) {
    .back-btn {
        font-size: 12px;
        padding: 10px;
        min-width: 65px;
        right: 20px;
    }
}

.back-btn:hover {
    background-image: radial-gradient(100% 100% at 100% 0, #f68f30 0, #c05d00 100%);
}

/* End of Footer Section */

/* Beginning of About Section */

.about-container {
    background-color: var(--mainBlack);
    padding: 40px;
    text-align: center;
}

.about-container h1 {
    color: var(--primaryColor);
    font-size: 2em;
    margin-bottom: 20px;
}

@media (max-width: 600px) {
    .about-container h1 {
        font-size: 1.4em;
        margin-bottom: 16px;
    }
}

@media (max-width: 450px) {
    .about-container h1 {
        font-size: 1.2em;
        margin-bottom: 13px;
    }
}

.about-container h2 {
    color: var(--mainGrey);
    font-size: 1.2em;
    line-height: 1.6;
}

@media (max-width: 600px) {
    .about-container h2 {
        font-size: 1em;
    }
}

@media (max-width: 450px) {
    .about-container h2 {
        font-size: .8em;
    }
}


/* End of About Section */

/* Beginning of BeefMain page */

.column-container {
    display: flex;
}

@media (max-width: 800px) {
    .column-container {
        display: inline;
    }
}

.column {
    flex: 1;
    box-sizing: border-box;
    padding: 40px;
    background-color: #FFBD59;
}

@media (max-width: 1340px) {
    .column {
        padding: 0px;
    }
}


.headline-main {
    text-align: center;
    font-size: 72px;
    background-color: var(--mainGrey);
}

@media (max-width: 1200px) {
    .headline-main {
        font-size: 60px;
    }
}

@media (max-width: 900px) {
    .headline-main {
        font-size: 48px;
    }
}

@media (max-width: 700px) {
    .headline-main {
        font-size: 40px;
        margin-bottom: -30px;
    }
}

.info-portion-container {
    text-align: center;
    padding: 40px;
    background-color: #e2e1e1;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1200px) {
    .info-portion-container {
        padding: 20px;
    }
}

@media (max-width: 900px) {
    .info-portion-container {
        padding: 20px;
    }
}

.info-heading {
    color: #333;
    font-size: 32px;
    margin-bottom: 20px;
}

@media (max-width: 1200px) {
    .info-heading {
        font-size: 18px;
    }
}

@media (max-width: 630px) {
    .info-heading {
        font-size: 16px;
    }
}

@media (max-width: 430px) {
    .info-heading {
        font-size: 14px;
    }
}


.info-portion-text {
    color: #555;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 30px;
}

@media (max-width: 900px) {
    .info-portion-text {
        font-size: 16px;
    }
}

@media (max-width: 630px) {
    .info-portion-text {
        font-size: 14px;
    }
}

@media (max-width: 430px) {
    .info-portion-text {
        font-size: 12px;
    }
}


.info-contact-text {
    color: #777;
    font-size: 18px;
    margin-bottom: 30px;
}

@media (max-width: 630px) {
    .info-contact-text {
        font-size: 14px;
    }
}

@media (max-width: 430px) {
    .info-contact-text {
        font-size: 10px;
    }
}

.contact-btn:hover {
    background-color: #e0883a;
}

.contact-link {
    text-decoration: none;
    color: #fff;
    background-color: #f09d51;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

@media (max-width: 630px) {
    .contact-link {
        font-size: 14px;
        padding: 6px 14px;
    }
}

@media (max-width: 430px) {
    .contact-link {
        font-size: 12px;
        padding: 10px 18px;
    }
}

.contact-link:hover {
    background-color: #ac5d13;
}

.how-much {
    height: 450px;
    width: auto;
    float: right;
    margin-right: 105px;
}

@media (max-width: 1340px) {
    .how-much {
        height: 400px;
        margin-right: 85px;
    }
}

@media (max-width: 1010px) {
    .how-much {
        margin-right: 15px;
    }
}

@media (max-width: 800px) {
    .how-much {
        display: none;
    }
}



.contact-btn:hover {
    background-color: #f68f30;
}

.img-gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5%;
    margin: 3.5%;
}

@media (max-width: 1200px) {
    .img-gallery {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .img-gallery {
        display: inline;
    }
}

.gallery-item {
    position: relative;
}

.image-container {
    width: 100%;
}

.gallery-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    display: block;
}

@media (max-width: 1200px) {
    .gallery-img {
        height: 250px;
    }
}

@media (max-width: 700px) {
    .gallery-img {
        margin-top: 60px;
    }
}


.image-text {
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 10px;
    text-align: center;
}

.amount-head {
    text-align: center;
    font-size: 72px;
    background-color: var(--mainGrey);
    margin-top: 200px;
}

@media (max-width: 1200px) {
    .amount-head {
        font-size: 60px;
    }
}

@media (max-width: 900px) {
    .amount-head {
        font-size: 48px;
    }
}

@media (max-width: 700px) {
    .amount-head {
        margin-top: 50px;
    }
}

.amount-container {
    margin-bottom: 150px;
}

@media (max-width: 700px) {
    .amount-container {
        margin-bottom: 50px;
    }
}

.amount-img {
    width: 100%;
    height: 300px;
    object-fit: contain;
    display: block;
}

@media (max-width: 1200px) {
    .amount-img {
        width: 100%;
    }
}


/* Beginning of Contact Page */

.hear-container {
    text-align: center;
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hear-heading {
    color: #333;
    font-size: 32px;
    margin-bottom: 20px;
}

.hear-subheading {
    color: #555;
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 30px;
}

form {
    display: block;
    background-color: #fff;
    box-shadow: 1px 1px 3px 2px #ddd;
    margin: 40px auto;
    max-width: 600px;
    max-height: 700px;
    padding: 25px 40px;
}

form input,
textarea {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 15px auto;
    padding: 8px;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 500;
}

form span {
    color: #e0883a;
}

form button {
    height: 30px;
    width: 80px;
    align-items: center;
    border: none;
    background-color: #e0883a;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    ;
    font-weight: 500;
    border-radius: 5px;
}

form button:hover {
    background-color: #ac5d13;
}

.form-text {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
}

.form-result-success {
    background-color: rgb(27, 177, 27);
    color: var(--mainGrey);
    appearance: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0 8px 8px, rgba(0, 0, 0, 0.3) 0 7px 13px -3px, rgba(0, 0, 0, 0.5) 0 0px 0 inset;
    color: var(--mainGrey);
    text-decoration: none;
    border: none;
    border-radius: 15px;
    padding: 10px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-weight: 600;
    right: 40px;
    top: 40%;
    white-space: normal;
    line-height: 1.5rem;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}

.form-result-fail {
    background-color: rgb(230, 0, 0);
    color: var(--mainWhite);
    appearance: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0 8px 8px, rgba(0, 0, 0, 0.3) 0 7px 13px -3px, rgba(0, 0, 0, 0.5) 0 0px 0 inset;
    color: var(--mainGrey);
    text-decoration: none;
    border: none;
    border-radius: 15px;
    padding: 10px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-weight: 600;
    right: 40px;
    top: 40%;
    white-space: normal;
    line-height: 1.5rem;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}

/* Beginning of Merchandise Page */

.info-container {
    margin: 150px;
    text-align: center;
}

@media (max-width: 600px) {
    .info-container {
        margin: 75px;
    }
}