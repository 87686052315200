.main-container {
    padding-bottom: 40px;
}

@media (max-width: 700px) {
    .main-container {
        padding-bottom: 0px;
    }
}

.accordion-container {
    max-width: 800px;
    margin: 0 auto;
    overflow: hidden;
}

.accordion-title {
    font-size: 55px;
    color: #222;
    margin-bottom: 40px;
    text-align: center;
    margin-top: 0px;
    padding-top: 40px;
}

@media (max-width: 600px) {
    .accordion-title {
        font-size: 35px;
    }
}

.accordion-faq-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #222;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.3s ease-in-out;
    margin-bottom: 30px;
    font-size: 12px;
}

@media (max-width: 600px) {
    .accordion-faq-heading {
        font-size: 9px;
    }
}

.accordion-faq-heading:hover {
    background-color: #f09d51;
}

.accordion-faq-heading h2 {
    color: #fff;
    font-weight: 600;
    margin: 0;
}

.accordion-faq p {
    padding: 25px;
    background-color: #e7e7e7;
    border-radius: 0 0 8px 8px;
    display: none;
    color: #333;
    line-height: 1.4;
    font-size: 18px;
}

@media (max-width: 700px) {
    .accordion-faq p {
        padding: 20px;
        font-size: 14px;
    }
}

.accordion-faq p.active {
    display: block;
}